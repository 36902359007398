exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".editor-buttons-style_buttonWrapper__2T38X {\n    display: inline-block;\n}\n\n.editor-buttons-style_button__1mbA8 {\n    /* background: #fbfbfb;\n    color: #888;\n    font-size: 18px;\n    border: 0;\n    padding-top: 5px;\n    vertical-align: bottom;\n    height: 34px;\n    width: 36px; */\n    background: #000;\n    color: #fff;\n    font-size: 19px;\n    border: 0;\n    padding-top: 3px;\n    vertical-align: bottom;\n    height: 34px;\n    width: 36px;\n    border-radius: 4px;\n}\n\n.editor-buttons-style_button__1mbA8 svg {\n    fill: #fff;\n}\n\n.editor-buttons-style_button__1mbA8:hover, .editor-buttons-style_button__1mbA8:focus {\n    /* background: #f3f3f3;\n    outline: 0; */\n    background: #777;\n    outline: 0;\n}\n\n.editor-buttons-style_active__1RxfN {\n    background: #000;\n    color: #fff;\n}\n\n.editor-buttons-style_active__1RxfN svg {\n    fill: #fff;\n}", ""]);

// exports
exports.locals = {
	"buttonWrapper": "editor-buttons-style_buttonWrapper__2T38X",
	"button": "editor-buttons-style_button__1mbA8",
	"active": "editor-buttons-style_active__1RxfN"
};