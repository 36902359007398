exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".imageStyles_image__kutbS {}\n", ""]);

// exports
exports.locals = {
	"image": "imageStyles_image__kutbS"
};