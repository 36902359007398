exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".mentionSuggestionsEntryStyles_mentionSuggestionsEntry__3XmAo {\n  padding: 7px 10px 3px 10px;\n  -webkit-transition: background-color 0.4s cubic-bezier(.27,1.27,.48,.56);\n  transition: background-color 0.4s cubic-bezier(.27,1.27,.48,.56);\n  color: black;\n}\n\n.mentionSuggestionsEntryStyles_mentionSuggestionsEntry__3XmAo:active {\n  background-color: #cce7ff;\n}\n\n.mentionSuggestionsEntryStyles_mentionSuggestionsEntryFocused__6nEGR {\n  background-color: #e6f3ff;\n}\n\n.mentionSuggestionsEntryStyles_mentionSuggestionsEntryText__bxqBG {\n  display: inline-block;\n  margin-left: 8px;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  max-width: 368px;\n  font-size: 0.9em;\n  margin-bottom: 0.2em;\n}\n\n.mentionSuggestionsEntryStyles_mentionSuggestionsEntryAvatar__3ZMRa {\n  display: inline-block;\n  width: 24px;\n  height: 24px;\n  border-radius: 12px;\n}\n", ""]);

// exports
exports.locals = {
	"mentionSuggestionsEntry": "mentionSuggestionsEntryStyles_mentionSuggestionsEntry__3XmAo",
	"mentionSuggestionsEntryFocused": "mentionSuggestionsEntryStyles_mentionSuggestionsEntryFocused__6nEGR mentionSuggestionsEntryStyles_mentionSuggestionsEntry__3XmAo",
	"mentionSuggestionsEntryText": "mentionSuggestionsEntryStyles_mentionSuggestionsEntryText__bxqBG",
	"mentionSuggestionsEntryAvatar": "mentionSuggestionsEntryStyles_mentionSuggestionsEntryAvatar__3ZMRa"
};