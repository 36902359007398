exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".mentionStyles_mention__3fgA9, .mentionStyles_mention__3fgA9:visited {\n  color: #575f67;\n  cursor: pointer;\n  display: inline-block;\n  background: #e6f3ff;\n  padding-left: 2px;\n  padding-right: 2px;\n  border-radius: 2px;\n  text-decoration: none;\n}\n\n.mentionStyles_mention__3fgA9:hover, .mentionStyles_mention__3fgA9:focus {\n  color: #677584;\n  background: #edf5fd;\n  outline: 0; /* reset for :focus */\n}\n\n.mentionStyles_mention__3fgA9:active {\n  color: #222;\n  background: #455261;\n}\n", ""]);

// exports
exports.locals = {
	"mention": "mentionStyles_mention__3fgA9"
};