exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".mentionSuggestionsStyles_mentionSuggestions__o0ybx {\n  border: 1px solid #eee;\n  margin-top: 0.4em;\n  position: absolute;\n  min-width: 220px;\n  max-width: 440px;\n  background: #fff;\n  border-radius: 2px;\n  box-shadow: 0px 4px 30px 0px rgba(220,220,220,1);\n  cursor: pointer;\n  padding-top: 8px;\n  padding-bottom: 8px;\n  z-index: 2;\n  display: flex;\n  flex-direction: column;\n  box-sizing: border-box;\n  -webkit-transform: scale(0);\n          transform: scale(0);\n}\n", ""]);

// exports
exports.locals = {
	"mentionSuggestions": "mentionSuggestionsStyles_mentionSuggestions__o0ybx"
};